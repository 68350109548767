<template>
  <div class="carousel-container" :data-test-id="testId + '-container'">
    <div class="carousel-container-top" :data-test-id="testId + '-top'">
      <div class="carousel-container-top-text" :data-test-id="testId + '-text'">
        <h3 v-if="title" class="carousel-container-top-text-title" :data-test-id="testId + '-title'">
          {{ title }} <span v-if="count">({{ count }})</span>
        </h3>
        <p v-if="subtitle" class="carousel-container-top-text-subtitle" :data-test-id="testId + '-subtitle'">
          {{ subtitle }}
        </p>
      </div>

      <MainButton
        v-if="linkLabel && linkSrc && displayButton"
        tag="a"
        :link="linkSrc"
        :label="linkLabel"
        :data-test-id="testId + '-see-everything'"
        class-variant="btn-grey btn-hover-light-grey"
        icon-position="RIGHT"
        class="hide-sm"
        aria-label="See everything"
      >
        <div :class="`${icon ? icon : 'icon-angle-right'} u-bg-dark-blue`" />
      </MainButton>

      <LinkResolver
        v-if="linkLabel && linkSrc"
        :link="linkSrc"
        class="carousel-container-top-link show-sm"
        aria-label="See everything"
      >
        <div :class="`${icon ? icon : 'icon-angle-right'} u-bg-dark-blue`" />
      </LinkResolver>
    </div>
    <div
      v-if="elementParent"
      class="carousel-wrapper"
      :data-test-id="testId + '-carousel-wrapper'"
      :style="`--item-width: ${itemWidth}px;--item-height: ${itemHeight}px`"
    >
      <div
        v-show="positionScroll > 0 && wrapperScrollWidth > 0"
        :id="elementParent + 'CarouselIconLeft'"
        :data-test-id="testId + '-icon-left'"
        class="carousel-icon icon-left"
        @click="carouselScrollTo('toLeft')"
      >
        <div class="icon-arrow-left u-bg-white" />
      </div>
      <div v-if="$slots.contentCarouselContainer" class="carousel" :data-test-id="testId + '-carousel-scroll'">
        <slot name="contentCarouselContainer" />
      </div>
      <div
        v-show="!(positionScroll === wrapperScrollWidth) && wrapperScrollWidth > 0"
        :id="elementParent + 'CarouselIconRight'"
        :data-test-id="testId + '-icon-right'"
        class="carousel-icon icon-right"
        @click="carouselScrollTo('toRight')"
      >
        <div class="icon-arrow-right u-bg-white" />
      </div>
    </div>
    <div v-else>
      <slot name="contentCarouselContainer" />
    </div>
    <MainButton
      v-if="linkLabel && linkSrc"
      tag="a"
      :link="linkSrc"
      :label="linkLabel"
      class-variant="btn-grey full-width"
      class="show-sm u-m-t-md"
    />
  </div>
</template>

<script setup>
import MainButton from '@/components/Buttons/MainButton/MainButton'
import LinkResolver from '@/components/LinkResolver/LinkResolver'
import { debounce } from '@/utils'

const props = defineProps({
  title: { type: String, default: '' },
  count: { type: Number, default: null },
  subtitle: { type: String, default: '' },
  linkLabel: { type: String, default: null },
  linkSrc: { type: String, default: null },
  elementParent: { type: String, default: '' },
  itemWidth: { type: [String, Number], default: 0 },
  itemHeight: { type: [String, Number], default: 0 },
  icon: { type: String, default: '' },
  displayButton: { type: Boolean, default: true },
  testId: { type: String, default: '' },
})

const carouselEl = ref(null)
const positionScroll = ref(0)
const wrapperScrollWidth = ref(0)

onMounted(() => {
  carouselEl.value = props.elementParent ? document.querySelector(`#${props.elementParent} .carousel`) : null
  if (carouselEl.value) {
    carouselEl.value.addEventListener('scroll', debounce(positionScrollHandler, 300), { passive: true })
    window.addEventListener('resize', debounce(handlePositionIconRight, 300), { passive: true })
    handlePositionIconRight()
    wrapperScrollWidth.value = carouselEl.value.scrollWidth - carouselEl.value.offsetWidth
  }
})

onBeforeUnmount(() => {
  if (carouselEl.value) {
    carouselEl.value.removeEventListener('scroll', positionScrollHandler)
    window.removeEventListener('resize', handlePositionIconRight)
  }
  carouselEl.value = null
})

const carouselScrollTo = moveTo => {
  const scrollWidthToLeft = carouselEl.value.offsetWidth - positionScroll.value
  const scrollWidthToRight = carouselEl.value.offsetWidth + positionScroll.value
  carouselEl.value.scrollTo({
    top: 0,
    left: moveTo === 'toRight' ? scrollWidthToRight : -scrollWidthToLeft,
    behavior: 'smooth',
  })
}
const positionScrollHandler = () => {
  positionScroll.value = carouselEl.value.scrollLeft
}
const handlePositionIconRight = () => {
  const iconRight = document.getElementById(props.elementParent + 'CarouselIconRight')
  if (iconRight && carouselEl.value) iconRight.style.left = carouselEl.value.offsetWidth + 'px'
}
</script>

<style lang="scss">
.carousel-container {
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $spacing-lg;

    &-text {
      display: flex;
      align-items: baseline;
      gap: $spacing-sm;

      @include mq($mq-sm) {
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-md;
      }

      p {
        line-height: 1 !important;
      }

      &-title {
        font-weight: 800;
        font-size: pxToRem(20px) !important;

        span {
          font-size: pxToRem(15px);
        }
      }

      &-subtitle {
        color: var(--steel-grey) !important;
      }
    }

    &-link {
      position: relative;
      top: -4px;
      align-self: flex-start;
    }
  }
}
.carousel-wrapper {
  position: relative;

  &:hover {
    .carousel-icon {
      display: flex;
    }
  }
}

.carousel {
  display: flex;
  gap: $spacing-md;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding-bottom: $spacing-md;

  @extend .product-custom-scrollbar;

  &-title {
    display: flex;
    align-items: baseline;
    gap: $spacing-sm;
    padding-bottom: $spacing-md;
    color: var(--dark-blue);
    @include mq($mq-sm) {
      display: block;
    }
  }
  &-subtitle {
    font-size: pxToRem(15px);
    font-weight: 400;
    color: var(--steel-grey);
    @include mq($mq-sm) {
      padding-top: $spacing-xs;
    }
  }

  &-icon {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-blue);
    height: 28px;
    width: 28px;
    border-radius: 50%;
    cursor: pointer;

    @include mq($mq-md) {
      display: flex;
    }

    &:hover {
      background-color: var(--primary);
    }

    &.icon-left {
      left: 0;
    }

    &.icon-left,
    &.icon-right {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -15px;
    }
  }
}
.cart-upsell-layout {
  .carousel {
    &::-webkit-scrollbar-track {
      background-color: var(--light-grey);
      border-radius: $spacing-xs;
    }
  }
}

.product-custom-scrollbar {
  // ::-webkit-scrollbar only for webkit browsers - not working on firefox
  &::-webkit-scrollbar {
    height: $spacing-xs;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--dark-blue);
    border-radius: $spacing-xs;
    &:hover {
      opacity: var(--twilight-blue);
    }
  }
  &::-webkit-scrollbar-thumb &::-webkit-scrollbar-track-piece {
    background-color: var(--bg-grey);
    border-radius: $spacing-xs;
  }
}
</style>
